import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar2 from '../containers/Saas/Navbar2';
import Footer from '../containers/Saas/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="DEVsheds" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar2 homePath="/" />
            </DrawerProvider>
          </Sticky>
          <center>
            <iframe
              style={{ width: '833px', height: '100vh', border: 0 }}
              src="https://docs.google.com/document/d/e/2PACX-1vQCSu0p96BzxYzr0wBdFkku0LjS8vOd5eLBBrBRugNRd6IDoXyl34n3Oe4eOFCw1SOiRyOfmUsp8gNG/pub?embedded=true"></iframe>
          </center>
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
